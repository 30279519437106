<template>
  <section class="mod-1">
    <div class="content">
      <div class="top">
        <span v-if="remainStudyTime > 0 && currentSection.progress != 100">
          <ClockCircleOutlined /> {{ $t("course.to_learn") }}&nbsp;
          <!-- 再学 -->
          <span style="color: #dc7a00; font-weight: bold">{{
            convertTimes(remainStudyTime)
          }}</span>
          {{ $t("course.lessons_learned") }}
          <!-- 课程学完 -->
        </span>
        <span class="green" v-if="currentSection.progress == 100">
          <CheckCircleOutlined />
          {{ $t("course.you_have_completed_this_section_of_study") }}
          <!-- 恭喜，您已完成本章节的学习 -->
        </span>
      </div>
      <a-spin :spinning="loadingPlayer" size="large" tip="Loading...">
        <div class="playArea">
          <div
            id="playArea"
            class="left"
            :class="[showItem == 2 ? 'background' : '']"
            :style="[showItem == 9 ? 'background:#ffffff;overflow:scroll; color: initial;' : '']"
          >
            <template v-if="openViewWatermark">
              <div class="water-marks" v-if="showItem == 3">
                <div class="item" v-for="i in 60" :key="i">
                  <OpenData
                    type="userName"
                    :openid="userInfo.realName"
                  />(<OpenData
                    type="departmentName"
                    :openid="userInfo.departmentName"
                  />)
                </div>
              </div>
              <div class="water-mark" v-else>
                <OpenData
                  type="userName"
                  :openid="userInfo.realName"
                />(<OpenData
                  type="departmentName"
                  :openid="userInfo.departmentName"
                />)
              </div>
            </template>
            <ali-player
              v-if="showItem == 1 || showItem == 2"
              class="video-player"
              ref="aliplayer"
              width="100%"
              height="100%"
              :enableSpeed="dataInfo.multiplePlay === 1"
              :autoplay="!isSafari && !isWxWork"
              :useH5Prism="true"
              x5_type="H5"
              :skinLayout="videoPlayer.skinLayout"
              :format="videoPlayer.format"
              :controlBarVisibility="
                showItem == 2 && sourcePath ? 'always' : 'hover'
              "
              @play="playHandler"
              @pause="pauseHandler"
              @speedChange="speedChangeHandler"
              @requestFullScreen="fullScreenHandler"
              @cancelFullScreen="cancelFullScreenHandler"
              @ended="onPlayerEnded($event)"
              @timeupdate="onPlayerTimeupdate($event)"
              :source="filePath"
            >
            </ali-player>
            <div class="audio-pdf" v-if="showItem == 2 && sourcePath">
              <iframe
                id="iframePDF"
                scrolling="yes"
                width="100%"
                height="100%"
                frameborder="0"
                marginheight="0"
                framespacing="0"
                marginwidth="0"
                allowfullscreen="true"
                webkitallowfullscreen="true"
                mozallowfullscreen="true"
                :src="sourcePath"
              ></iframe>
            </div>
            <div id="CCPlay" v-if="showItem == 11"></div>
            <iframe
              id="iframePDF"
              v-if="showItem == 3"
              scrolling="yes"
              width="100%"
              height="100%"
              frameborder="0"
              marginheight="0"
              framespacing="0"
              marginwidth="0"
              allowfullscreen="true"
              webkitallowfullscreen="true"
              mozallowfullscreen="true"
              :src="filePath"
            ></iframe>
            <div v-if="showItem == 4" style="overflow: auto; height: 100%">
              <img :src="filePath" style="max-width: 100%" />
            </div>
            <div  v-if="showItem === 5 || showItem === 9"
                :contenteditable="!isAllowcopying"
                v-html="filePath"
                :class="{ unselectable: isAllowcopying === 1 }" 
                style="width: 100%; height: 100%"
></div>
            <iframe
              id="scormPDF"
              v-if="showItem == 10"
              scrolling="yes"
              width="100%"
              height="100%"
              frameborder="0"
              marginheight="0"
              framespacing="0"
              marginwidth="0"
              allowfullscreen="true"
              webkitallowfullscreen="true"
              mozallowfullscreen="true"
              :src="filePath"
            ></iframe>
          </div>
          <div class="right">
            <ul>
              <li
                :class="[{ on: currentIndex == index }]"
                v-for="(item, index) in dataInfo.details"
                :key="index"
                @click="playSection(index)"
              >
                <PlaySquareOutlined
                  :style="{
                    fontSize: '16px',
                    position: 'absolute',
                    left: '18px',
                    top: '15px',
                    color: '#999999',
                  }"
                />
                <div class="catalog-detail">
                  <h3 class="title">
                    {{ index + 1 }}.
                    <a-tooltip>
                      <template #title>{{ item.fileName }}</template>
                      {{ item.fileName }}
                    </a-tooltip>
                  </h3>
                  <div class="info">
                    <span
                      >{{ getFileType(item.fileType)
                      }}{{
                        item.fileType == 6 ? "&nbsp;&nbsp;&nbsp;" : ""
                      }}</span
                    >
                    <span
                      >{{ $t("CM_LB_Progress") }}：{{ item.progress }}%</span
                    >
                    <!-- 进度 -->
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </a-spin>
    </div>
  </section>
  <section class="mod-2">
    <div class="course-detail">
      <div class="title">
        <span class="ti">
          <a-tooltip>
            <template #title>{{ dataInfo.courseName }}</template>
            {{ dataInfo.courseName }}
          </a-tooltip>
        </span>
        <div class="num">（{{ $t("course.classes", [sectionTotal]) }}）</div>
      </div>
      <!-- 评估表 -->
      <div class="rate" v-if="questionnaire && questionnaire.questionnaireId">
        <div class="ss">
          {{
            dataInfo.questionnaireTotalUser > 0
              ? (
                  dataInfo.questionnaireTotalScore /
                  dataInfo.questionnaireTotalUser
                ).toFixed(1)
              : "0.0"
          }}
        </div>
        <div>
          <div class="num">
            {{ $t("course.people_score", [dataInfo.questionnaireTotalUser]) }}
          </div>
          <!-- 人打分 -->
        </div>
      </div>
      <!-- 星评 -->
      <div class="rate" v-else>
        <div class="ss">
          {{
            dataInfo.starUsers > 0
              ? (dataInfo.starScore / dataInfo.starUsers).toFixed(1)
              : "0.0"
          }}
        </div>
        <div>
          <div class="num">
            {{ $t("course.people_score", [dataInfo.starUsers]) }}
          </div>
          <!-- 人打分 -->
        </div>
      </div>
      <div class="details">
        <!-- 课程介绍 -->
        <div class="tag">{{ $t("course.course_intro") }}</div>
        <div class="other">
          <div class="other-item">
            <!-- 讲师 -->
            <div class="label">{{ $t("XB_Course_Lecturer") }}:</div>
            <div class="value">
              <template v-if="lecturers.length">
                <template
                  v-for="(lecturer, lecturerIndex) in lecturers"
                  :key="lecturerIndex"
                >
                  <OpenData type="userName" :openid="lecturer" />
                  <template v-if="lecturerIndex + 1 != lecturers.length"
                    >、</template
                  >
                </template>
              </template>
              <!-- 暂无 -->
              <template v-else>{{ $t("no_lecturer") }}</template>
            </div>
          </div>
          <div class="other-item">
            <!-- 学习时长 -->
            <div class="label">{{ $t("XB_LearningDuration") }}:</div>
            <div class="value">
              {{
                dataInfo.courseType == 2
                  ? "--"
                  : formatTime(dataInfo.coursePeriod)
              }}
            </div>
          </div>
          <div class="other-item">
            <!-- 学习人数： -->
            <div class="label">{{ $t("subject.learners") }}:</div>
            <div class="value">
              {{ courseStudyTotal }}{{ $t("subject.people") }}
            </div>
          </div>
          <div class="other-item">
            <!-- 收藏： -->
            <div class="label">{{ $t("Lab_CourseDetail_Collect") }}:</div>
            <div class="value">
              {{ dataInfo.favorites }}{{ $t("subject.people") }}
            </div>
          </div>
          <div class="other-item" v-if="dataInfo.studyTime">
            <!-- 学时： -->
            <div class="label">{{ $t("CM_Period") }}:</div>
            <div class="value">
              {{ dataInfo.studyTime }}
            </div>
          </div>
          <div class="other-item" v-if="dataInfo.studyScore">
            <!-- 学分： -->
            <div class="label">{{ $t("CM_LB_Credit") }}:</div>
            <div class="value">
              {{ dataInfo.studyScore }}
            </div>
          </div>
          <div class="other-item" v-if="dataInfo.studyIntegral">
            <!-- 积分： -->
            <div class="label">{{ $t("LB_Pro_Integral") }}:</div>
            <div class="value">
              {{ dataInfo.studyIntegral }}
            </div>
          </div>
          <div class="other-item intro" v-if="dataInfo.intro">
            <!-- 介绍 -->
            <div class="label">{{ $t("Home.introduce") }}:</div>
            <div class="value">
              {{ dataInfo.intro }}
            </div>
          </div>
        </div>
      </div>
      <div class="total-progress">
        <a-progress
          type="circle"
          strokeColor="#108ee9"
          :percent="dataInfo.progress"
        >
          <template #format="percent">
            <span class="percent">{{ percent }}%</span>
            <!-- 总进度 -->
            <div class="progress-txt">{{ $t("course.total_progress") }}</div>
          </template>
        </a-progress>
      </div>
    </div>
    <div class="course-setting" v-if="showCompleteCondition">
      <!-- 课程完成条件 -->
      <div class="title">{{ $t("XB_Course_CompletionConditions") }}</div>
      <div class="setting">
        <div class="setting-item" v-if="questionnaire">
          <div class="icon quesition-icon"></div>
          <div class="info">
            <!-- 评分/评估 -->
            <div class="type">
              {{ $t("LB_Favorite_Score") }}/{{ $t("course.evaluate") }}
            </div>
            <div class="complete">
              <div class="score" v-if="questionnaire.score">
                {{ $t("exam.nth_score", [questionnaire.score]) }}
              </div>
              <div
                class="status"
                :class="'status-' + questionnaire.customStatus"
              >
                {{ setStatus[questionnaire.customStatus] }}
              </div>
              <div class="time" v-if="questionnaire.submitTime">
                {{ dateFormat(questionnaire.submitTime, "YYYY-MM-DD") }}
              </div>
            </div>
          </div>
          <div class="btn">
            <!-- 去评分 -->
            <a-button
              type="primary"
              block
              shape="round"
              @click="handelCondition(1)"
            >
              {{
                questionnaire.customStatus ? $t("cm_view") : $t("course.go", 0)
              }}
            </a-button>
          </div>
          <div
            class="complete-icon"
            v-if="questionnaire.customStatus == 2"
          ></div>
        </div>
        <div class="setting-item" v-if="homework">
          <div class="icon homework-icon"></div>
          <div class="info">
            <!-- 作业 -->
            <div class="type">{{ $t("course.homework") }}</div>
            <div class="complete">
              <div class="score" v-if="homework.score">
                {{ $t("exam.nth_score", [homework.score]) }}
              </div>
              <div class="status" :class="'status-' + homework.customStatus">
                {{ setStatus[homework.customStatus] }}
              </div>
              <div class="time" v-if="homework.customStatus">
                {{ dateFormat(homework.completeTime, "YYYY-MM-DD") }}
              </div>
            </div>
          </div>
          <div class="btn">
            <!-- 写作业 -->
            <a-button
              type="primary"
              block
              shape="round"
              @click="handelCondition(2)"
            >
              {{ homework.customStatus ? $t("cm_view") : $t("course.go", 1) }}
            </a-button>
          </div>
          <div class="complete-icon" v-if="homework.customStatus == 2"></div>
        </div>
        <div class="setting-item" v-if="exam">
          <div class="icon exam-icon"></div>
          <div class="info">
            <!-- 考试 -->
            <div class="type">{{ $t("Sys_Exam") }}</div>
            <div class="complete">
              <div
                class="score"
                :class="{ nopass: !exam.isPass }"
                v-if="exam.customStatus == 2"
              >
                {{ $t("exam.nth_score", [exam.score]) }}
              </div>
              <div class="status" :class="'status-' + exam.customStatus">
                {{ setStatus[exam.customStatus] }}
              </div>
              <div class="time" v-if="exam.submitTime">
                {{ dateFormat(exam.submitTime, "YYYY-MM-DD") }}
              </div>
            </div>
          </div>
          <div class="btn">
            <!-- 去考试 -->
            <a-button
              type="primary"
              block
              shape="round"
              @click="handelCondition(3)"
            >
              {{ exam.customStatus ? $t("cm_view") : $t("course.go", 2) }}
            </a-button>
          </div>
          <div class="complete-icon" v-if="exam.customStatus == 2"></div>
        </div>
      </div>
    </div>
    <div class="course-other" :class="!showCompleteCondition && 'top40'">
      <div class="left">
        <a-tabs v-model:activeKey="activeKey" @change="changeTabs">
          <!-- 课程目录 -->
          <a-tab-pane key="1" :tab="$t('course.course_catalog')">
            <ul class="catalog-ul">
              <li
                v-for="(item, index) in dataInfo.details"
                :key="index"
                @click="playSection(index)"
                :class="[
                  {
                    ing: currentIndex == index && item.progress != 100,
                    complete: item.progress == 100 || dataInfo.progress == 100,
                  },
                ]"
              >
                <div class="index">
                  {{ $t("course.section", [index + 1]) }}
                  <!-- 第 节 -->
                </div>
                <div class="catalog-detail">
                  <h3 class="title">
                    <PlaySquareOutlined
                      :style="{
                        fontSize: '18px',
                        color: '#999999',
                        verticalAlign: '-3px',
                        marginRight: '5px',
                      }"
                    />
                    <a-tooltip>
                      <template #title>{{ item.fileName }}</template>
                      {{ item.fileName }}
                    </a-tooltip>
                  </h3>
                  <div class="info">
                    <span>{{ getFileType(item.fileType) }}</span>
                    <span v-if="item.fileType == 3"
                      >{{ $t("XB_PagesNumber") }}：{{ item.pages }}</span
                    >
                    <span v-if="item.fileType == 9"
                      ></span
                    >
                    <!-- 页数 -->
                    <span v-else
                      >{{ $t("XB_Duration") }}：{{
                        dataInfo.courseType == 2
                          ? "--"
                          : formatSeconds(item.times)
                      }}</span
                    >
                    <!-- 时长： -->
                    <span
                      >{{ $t("CM_LB_Progress") }}：{{ item.progress }}%</span
                    >
                    <!-- 进度： -->
                  </div>
                </div>
                <div
                  class="zt"
                  v-if="currentIndex == index && item.progress != 100"
                >
                  {{ $t("XB_Studying") }}
                  <!-- 学习中 -->
                </div>
                <div class="zt" v-else-if="item.progress == 100">
                  <div class="icon-wc"></div>
                  {{ $t("XB_FinishedLearning") }}
                  <!-- 已学完 -->
                </div>
                <div class="zt" v-else-if="item.progress > 0">
                  {{ $t("XB_NotFinishedLearning") }}
                </div>
                <!-- 未学完 -->
                <div class="zt" v-else>{{ $t("CM_NotLearning") }}</div>
                <!-- 未学习 -->
              </li>
            </ul>
          </a-tab-pane>
          <!-- 学习情况 -->
          <a-tab-pane
            key="2"
            :tab="$t('XB_LearningSituation')"
            v-if="taskId === 0"
          >
            <div class="learnUsers">
              <!-- 学习人数 -->
              {{ $t("subject.learners")
              }}<span style="color: #bbbbbb; margin-left: 10px">{{
                courseStudyTotal
              }}</span>
            </div>
            <a-list
              class="demo-loadmore-list"
              :loading="loading"
              item-layout="horizontal"
              :data-source="courseStudyList"
            >
              <template #loadMore>
                <div
                  :style="{
                    textAlign: 'center',
                    marginTop: '30px',
                    height: '32px',
                    lineHeight: '32px',
                    color: '#BCBCBC',
                  }"
                >
                  <a-spin v-if="loading" />
                  <div v-else-if="finished">{{ $t("no_more") }}</div>
                  <!-- 没有更多了 -->
                  <a-button v-else @click="loadMore">{{
                    $t("load_more")
                  }}</a-button>
                  <!-- 加载更多 -->
                </div>
              </template>
              <template #renderItem="{ item }">
                <a-list-item>
                  <a-list-item-meta>
                    <template #title>
                      <OpenData type="userName" :openid="item.realName" />
                    </template>
                    <template #avatar>
                      <a-avatar :src="item.portrait" :size="45" />
                    </template>
                    <template #description>
                      <OpenData
                        type="departmentName"
                        :openid="item.department"
                      />
                    </template>
                  </a-list-item-meta>
                  <div style="color: #1ab700">{{ item.progress }}%</div>
                </a-list-item>
              </template>
            </a-list>
          </a-tab-pane>
          <!-- 课程评论 -->
          <a-tab-pane
            v-if="hasComment"
            key="3"
            :tab="
              $t('course.course_comment') + '（' + commentParams.Total + '）'
            "
            forceRender
          >
            <my-comment
              v-if="commentParams.resourceId > 0"
              :CommentParams="commentParams"
            ></my-comment>
          </a-tab-pane>
          <!-- 课程评分 -->
          <a-tab-pane
            v-if="documentList.length"
            key="4"
            :tab="'学习资料'"
            forceRender
          >
            <div class="doc">
              <!-- <a-list :data-source="documentList">
                <template #renderItem="{ item }">
                  <a-list-item>
                    <template #actions>
                      <a>下载</a>
                    </template>
                    {{ item.fileName }}
                  </a-list-item>
                </template>
              </a-list> -->
              <div
                class="doc-item"
                v-for="item in documentList"
                :key="item.documentId"
              >
                <div class="title" @click="previewDoc(item)">
                  {{ item.fileName }}
                </div>
                <div class="action" v-if="item.allowDownload">
                  <a-button type="link" @click="clickDownload(item)">{{
                    $t("cm_download")
                  }}</a-button>
                </div>
              </div>
            </div>
          </a-tab-pane>
        </a-tabs>
      </div>
      <div class="right">
        <!-- 推荐课程 -->
        <a-card :title="$t('Pub_RecommendedCourse')" style="width: 290px">
          <template #extra>
            <a href="javascript:;" @click="brushList()">
              <SyncOutlined :style="{ fontSize: '12px' }" />
              {{ $t("cm_refresh") }}
              <!-- 刷一刷 -->
            </a>
          </template>
          <template v-if="recommendList.length > 0">
            <a
              :href="`/course/detail?id=${item.courseId}}${
                newWindow == 1 ? '&ddtab=true' : ''
              }`"
              :target="newWindow == 1 ? '_blank' : '_self'"
              class="tjkc-li"
              v-for="(item, index) in recommendList.slice(0, 6)"
              :key="index"
            >
              <img :src="item.coverImg" class="coverImg" />
              <div class="info">
                <p class="title">{{ item.courseName }}</p>
                <p>
                  {{ $t("subject.learners") }}：{{ item.learnUsers
                  }}{{ $t("subject.people") }}
                </p>
                <!-- 学习人数： 人-->
                <p>
                  <!-- 讲师： -->
                  {{ $t("XB_Course_Lecturer") }}：<template
                    v-if="item.lecturers"
                    ><OpenData
                      type="userName"
                      :openid="item.lecturers[0].name" /></template
                  ><template v-else>-</template>
                </p>
              </div>
            </a>
          </template>
          <a-empty v-else :description="$t('CM_Empty')" />
          <!-- CM_Empty -->
        </a-card>
      </div>
    </div>
    <a-affix
      :offset-top="offsetTop"
      style="position: absolute; top: 200px; right: 50%; text-align: right"
    >
      <div style="transform: translateX(682px)">
        <div
          style="
            margin-bottom: 11px;
            border: 1px solid #e6e6e6;
            border-radius: 4px;
            padding: 2px;
            background: #fff;
          "
        >
          <a class="float" @click="clickFavorite()">
            <StarOutlined v-if="!dataInfo.isFavorite" />
            <StarFilled v-else style="color: #ff9900" />
          </a>
          <a class="float" @click="activeKey = '3'" v-if="hasComment">
            <MessageOutlined />
          </a>
        </div>
        <div
          style="
            border: 1px solid #e6e6e6;
            border-radius: 4px;
            padding: 2px;
            background: #fff;
          "
        >
          <a class="float" @click="backTop()">
            <UpOutlined />
          </a>
        </div>
      </div>
    </a-affix>
  </section>
  <Face
    v-if="dataInfo.openFace == 1 || dataInfo.openFace == 3 || openFace"
    :name="taskId > 0 && openFace ? `task_${taskId}` : `course_${courseId}`"
    @confirm="startPlay"
  />
  <!-- 三院火狐跳转谷歌弹窗 -->
  <a-modal
    v-model:visible="htsyVisible"
    title="系统提示"
    :keyboard="false"
    :maskClosable="false"
    centered
    @ok="handleOk"
  >
    <template #footer>
      <div class="btns">
        <a-button key="submit" type="primary" @click="handleOk"
          >使用Google播放</a-button
        >
      </div>
    </template>
    <h3 class="modal-title">
      <ExclamationCircleOutlined />
      Firfox版本较低，请使用Google浏览器播放课程。
    </h3>
    <div class="modal-tips">
      <p>首次使用配置说明：</p>
      <p>
        1、请下载两个配置文件：
        <a
          :href="dataPath + '/openchrome49.reg?ddtab=true'"
          target="blank"
          download
          >注册表、</a
        >
        <a
          :href="dataPath + '/Chrome(XP).zip?ddtab=true'"
          target="blank"
          download
          >谷歌浏览器。</a
        >
      </p>
      <p>2、双击注册表，同意添加。</p>
      <p>
        3、请将谷歌浏览器放至下述目录位置，右键点击压缩包，选择【解压到当前文件夹】即可。
      </p>
      <p>目录位置：C:\Envi\Explorer</p>
      <p>软件实际运行目录：C:\Envi\Explorer\Chrome49\</p>
    </div>
  </a-modal>
  <!-- 作业 -->
  <homework ref="homeworkRef" @updata:homework="getPlusDetail" />
  <!-- 星评 -->
  <rate
    ref="rateRef"
    :score="dataInfo.myStar"
    :disabled="dataInfo.myStar > 0"
    @on-submit="rateScore"
  />
  <!-- 资料查看 -->
  <docPreview
    :isVisible="previewFile.visible"
    :documentId="previewFile.documentId"
    :openViewWatermark="openViewWatermark"
    :fileStatus="previewFile.fileStatus"
    :fileType="previewFile.fileType"
    :filePath="previewFile.filePath"
    :fileName="previewFile.fileName"
    :fileHome="previewFile.fileHome"
    :isAllowcopying="previewFile.isAllowcopying"
    @previewEnd="
      (e) => {
        previewFile.visible = e;
      }
    "
  />
</template>

<script>
import { useI18n } from "vue-i18n";
import {
  detail,
  courseList,
  members,
  favorite,
  star,
  courseLearning,
  courseStartLearn,
  zjyxSing,
  plusDetail,
} from "@/api/course";
import { getTaskDetail, taskLearning, taskStartLearn } from "@/api/project";
import {
  getCurrentInstance,
  reactive,
  toRefs,
  ref,
  onMounted,
  onUnmounted,
  watch,
  createVNode,
  h,
} from "vue";
import { useRouter, useRoute } from "vue-router";
import { useStore } from "vuex";
import VueAliplayer from "@/components/course/vue-aliplayer";
import myComment from "@/components/my-comment";
import ls from "@/utils/local-storage";
import {
  convertTimes,
  formatTime,
  fullscreen,
  isFullscreen,
  dateFormat,
  fileDownload,
} from "@/utils/tools.js";
import { Modal } from "ant-design-vue";
import Face from "@/components/Face.vue";
import OpenData from "@/components/OpenData.vue";
import { onShow, onHide } from "@/utils/tools";
import { ExclamationCircleOutlined } from "@ant-design/icons-vue";
import { createAuth, toDownload } from "@/api/user";
import _ from "lodash";
import { getFileType } from "@/utils/business.js";
import homework from "@/components/homework/index";
import rate from "@/components/course/rate";
import docPreview from "@/components/preview/index.vue";
import { getDetail as getFileDetail } from "@/api/doc";
export default {
  components: {
    "ali-player": VueAliplayer,
    "my-comment": myComment,
    Face,
    OpenData,
    homework,
    rate,
    docPreview,
  },
  props: {
    taskIdP: {
      type: Number,
      default: 0,
    },
    sidP: {
      type: Number,
      default: 0,
    },
    didP: {
      type: Number,
      default: 0,
    },
  },
  setup(props) {
    const { t: $t } = useI18n();
    const { proxy } = getCurrentInstance();
    const route = useRoute();
    const router = useRouter();
    const store = useStore();
    const aliplayer = ref(null);
    const ccplayer = ref(null); // 中交云学播放器
    const state = reactive({
      currentSpeed: "1.0",
      courseId: parseInt(
        route.query.id ||
          (route.query.htsyautoauth
            ? route.query.htsyautoauth.split("*")[1]
            : 0)
      ),
      taskId: props.taskIdP || parseInt(route.query.taskId || 0),
      sid: props.sidP || parseInt(route.query.sid || 0),
      did: props.didP || parseInt(route.query.did || 0),
      logId: 0, //记录编号
      learnId: 0, //学习编号
      facePic: "", //人脸采集图片
      openFace: false, //项目包是否配置人脸采集
      activeKey: "1",
      dataInfo: {},
      currentSection: {},
      sectionTotal: 0, // 多少章节
      isAllowcopying: 1, // 1:不允许复制  0:允许复制
      showItem: 0,
      loadingPlayer: false,
      videoPlayer: {
        source: "",
        format: "mp4",
        skinLayout: [
          { name: "bigPlayButton", align: "blabs", x: 30, y: 80 },
          {
            name: "controlBar",
            align: "blabs",
            x: 0,
            y: 0,
            children: [
              { name: "progress", align: "blabs", x: 0, y: 44 },
              { name: "playButton", align: "tl", x: 15, y: 12 },
              { name: "timeDisplay", align: "tl", x: 10, y: 7 },
              {
                name: "fullScreenButton",
                align: "tr",
                x: 10,
                y: 12,
              },
              { name: "setting", align: "tr", x: 15, y: 12 },
              { name: "volume", align: "tr", x: 5, y: 10 },
            ],
          },
        ],
      },
      currentIndex: -1, // 当前播放章节的索引
      filePath: "", // 文件路径
      sourcePath: "", // 音频附件路径
      timer: null, // 计算器
      timer2: null, // 计算器
      currentTime: 0, // 课程已计时秒数
      documentTimes: 0, //知识每页学习秒数
      remainStudyTime: 0, // 剩余学习时间（秒）
      setTimeFlag: 1, // 1：从上次播放进度开始播放
      startIndex: 0, // 当前课程播放0次
      isPause: false, // 是否暂停
      pauseTime: 0,
      userInfo: ls.get("userInfo"),
      recommendList: [], // 推荐课程列表
      courseStudyList: [], // 课程学习人员列表
      courseStudyTotal: 0,
      searchParams: {
        page: 1,
        pageSize: 15,
        id:
          route.query.id ||
          (route.query.htsyautoauth
            ? route.query.htsyautoauth.split("*")[1]
            : 0),
      },
      loading: true,
      finished: false,
      commentParams: {
        page: 1,
        pageSize: 10,
        resourceId: route.query.id || 0,
        type: 1, //0知识 1课程 2任务 3新闻
        Total: 0,
      },
      lecturers: [], // 讲师
      rateValue: 0, // 打分数（评分）
      offsetTop: 250,
      antiCheatingTimer: null, //反作弊计时器
      remindTime: 0, //15分钟
      psign: "",
      hasComment: false,
      openViewWatermark: true,
      openDownloadWatermark: false,
      scormCommitData: {},
      // 课程完成条件
      showCompleteCondition: false,
      exam: null,
      homework: null,
      questionnaire: null,
      setStatus: {
        0: $t("CM_NotCompleted"), // 未完成
        1: $t("XB_Exam_PendingApproval"), // 待批阅
        2: $t("CM_Completed"), // 已完成
      },
      documentList: [],
      previewFile: {
        visible: false,
        documentId: 0,
        fileStatus: 0,
        fileType: 0,
        filePath: "",
        fileName: "",
        fileHome: "",
      },
      taskTotals: 0, // 条件总数
      completeTotals: 0, // 完成数
      isCanExam: false, // 是否能考试
      plusTaskId: props.taskIdP || parseInt(route.query.taskId || 0), // 课程完成条件相关taskId参数
      memberOnce: true, // 学习人员情况调用一次
      intervalSpeed: 30, // 每30秒提交一次
    });

    watch(
      () => store.getters.companyInfo,
      (company) => {
        state.hasComment = company.useComment == 1;
        state.openViewWatermark = company.isOpenViewWatermark == 1;
        state.openDownloadWatermark = company.isOpenDownloadWatermark == 1;
      },
      { immediate: true }
    );

    const createPlayer = () => {
      ccplayer.value = window.createCCH5Player({
        vid: state.currentSection.outFileId,
        siteid: "46D5B89ABE2D25D6",
        playtype: 1,
        isShare: false,
        open_barrage: false,
        isShowExercise: false,
        isShowQuestions: false,
        isShowVisitor: false,
        rate_allow_change: !(
          state.dataInfo.multiplePlay == 2 &&
          state.currentSection.progress != 100
        ),
        progressbar_enable:
          state.dataInfo.draggable == 2 && state.currentSection.progress != 100
            ? 0
            : 1,
        vc: state.psign,
        autoStart: false,
        width: "100%",
        height: "100%",
        parentNode: document.getElementById("CCPlay"),
      });
      // console.log(ccplayer.value);
    };

    const ccPlay = () => {
      ccplayer.value && ccplayer.value.destroy();
      createPlayer();
    };

    const ua =
      navigator && navigator.userAgent
        ? navigator.userAgent.toLowerCase() || ""
        : "";
    let isWxWork = /wxwork/i.test(ua);
    let isDingding = /dingtalk/i.test(ua);
    let isSafari =
      /Safari/.test(navigator.userAgent) && !/Chrome/.test(navigator.userAgent);
    // 课程完成条件
    const initlearnSet = async (list) => {
      if (!list) return;
      state.showCompleteCondition = true;
      state.taskTotals = list.length;
      list.forEach((item) => {
        if (item.exam) {
          item.exam.coursePlusId = item.coursePlusId;
          state.exam = item.exam;
        } else if (item.homework) {
          item.homework.coursePlusId = item.coursePlusId;
          state.homework = item.homework;
        } else if (item.questionnaireId >= 0) {
          state.questionnaire = item;
          if (item.coursePlusType == 4) {
          }
        }
      });
      getPlusDetail();
    };
    const starComplete = () => {
      state.questionnaire.customStatus = state.dataInfo.myStar > 0 ? 2 : 0;
      state.questionnaire.score = state.dataInfo.myStar;
      if (state.questionnaire.customStatus === 2) {
        state.completeTotals += 1;
        updataProgress();
      }
    };
    const getPlusDetail = async () => {
      const res = await plusDetail({
        courseId: state.courseId,
        taskId: state.plusTaskId,
      });
      // customStatus 0 未完成 1 待批阅 2 已完成
      if (res.ret === 0) {
        const data = res.data;
        state.completeTotals = 0;
        if (data.exam) {
          const { passTime, score, passScore, submitTime } = data.exam;
          state.exam.customStatus = passTime ? 2 : 0;
          // if (state.exam.completeCondition == 1) {
          //   // 提交完成
          //   state.exam.customStatus = status > 1 ? 2 : status;
          // } else {
          //   // 及格完成
          //   if (status == 8) {
          //     state.exam.customStatus = 2
          //   } else {
          //     state.exam.customStatus = status == 2 ? 0 : status;
          //   }
          // }
          state.exam.score = score;
          state.exam.submitTime = submitTime;
          state.exam.isPass = score >= passScore;
          if (state.exam.customStatus === 2) {
            state.completeTotals += 1;
          }
        }
        if (data.homework) {
          const { status, score, lastTime } = data.homework.myHomework;
          if (state.homework.completeCondition == 1) {
            // 提交完成
            state.homework.customStatus = status == 1 ? 2 : 0;
          } else {
            // 批阅完成
            if (status == 2) {
              state.homework.customStatus = 1;
            } else {
              state.homework.customStatus = [1].includes(status) ? 2 : 0;
            }
          }
          state.homework.score = score;
          state.homework.completeTime = lastTime;

          if (state.homework.customStatus === 2) {
            state.completeTotals += 1;
          }
        }
        if (data.questionnaire) {
          // 评估表是否完成
          const { complete, submitTime } = data.questionnaire;
          state.questionnaire.customStatus = complete ? 2 : 0;
          state.questionnaire.submitTime = submitTime;

          if (state.questionnaire.customStatus === 2) {
            state.completeTotals += 1;
          }
        } else if (state.questionnaire?.coursePlusType == 4) {
          // 星评是否完成
          starComplete();
        }

        updataProgress();
      }
    };
    const getDetail = () => {
      let action = detail;
      let params = { id: state.courseId };
      if (state.taskId > 0) {
        action = getTaskDetail;
        params = { id: state.taskId, did: state.did };
      }
      action(params).then((res) => {
        if (res.ret === 0) {
          if (state.taskId > 0) {
            state.dataInfo = res.data.course;
            state.courseId = state.dataInfo.courseId;
            state.searchParams.id = state.dataInfo.courseId;
            state.commentParams.resourceId = state.dataInfo.courseId;
            state.openFace =
              res.data.openFace == 1 || res.data.openFace == 3 ? true : false;
            if (res.data.course.completeFromSync) {
              state.plusTaskId = 0;
            }
          } else {
            state.dataInfo = res.data;
          }
          if (state.dataInfo.preventOnhook > 0) {
            state.remindTime = state.dataInfo.preventOnhook * 60;
          }
          state.documentTimes = state.dataInfo.documentTimes;
          state.lecturers = [];
          (state.dataInfo.lecturers || []).map((item) => {
            state.lecturers.push(item.name);
          });
          if (state.dataInfo.DocumentList) {
            state.documentList = state.dataInfo.DocumentList.map((item) => {
              item.fileName2 = item.fileName;
              item.fileName = item.fileName + "." + item.suffix;
              return item;
            });
          }
          state.courseStudyTotal = state.dataInfo.learnUsers || 1;
          initlearnSet(state.dataInfo.CoursePlusList);
          if (
            state.dataInfo.openFace == 1 ||
            state.dataInfo.openFace == 3 ||
            state.openFace
          ) {
            return false;
          }
          startPlay();
        } else {
          if (res.data && res.data.learnOrder) {
            router.replace({
              path: `/project/detail`,
              query: { id: state.taskId },
            });
          } else if (res.ret == 1 && res.msg == $t("XB_Parameter_Error")) {
            // 参数错误
            router.replace({
              path: `/error`,
              query: {
                title: $t("course.course_not_exist"),
                // 抱歉，您访问的课程不存在。
                path: "/course",
                name: $t("XB_CourseCenter"),
                // 课程中心
              },
            });
          } else {
            router.replace({
              path: `/error`,
              query: {
                title: $t("sorry_you_visited", [res.msg]),
                // 抱歉，您访问的
                path: "/course",
                name: $t("XB_CourseCenter"),
                // // 课程中心
              },
            });
          }
        }
      });
    };

    const startPlay = (data) => {
      if (data) state.facePic = data;
      //过滤已完成章节，播放未完成章节
      if (state.dataInfo.details && state.dataInfo.details.length > 0) {
        state.sectionTotal = state.dataInfo.details.length;
        for (let i = 0; i <= state.dataInfo.details.length - 1; i++) {
          if (state.dataInfo.details[i].progress != 100) {
            playSection(i);
            return false;
          }
        }
      }
      playSection(0);
    };

    const receiveMessage = (e) => {
      if (e.data == "pdf-fullscreen") {
        fullscreen(document.querySelector("#playArea"));
      } else if (e.data == "pdf-fullscreen-status") {
        // let pdfRef = document.querySelectorAll("#iframePDF")[0].contentWindow;
        // let presentationModeButton =
        //   pdfRef.PDFViewerApplication.appConfig.toolbar.presentationModeButton;
        // if (isFullscreen()) {
        //   presentationModeButton.classList.add("hidden");
        // } else {
        //   presentationModeButton.classList.remove("hidden");
        // }
      } else if (
        e.data &&
        typeof e.data == "string" &&
        e.data.indexOf("scormCommitData") != -1
      ) {
        let d = JSON.parse(e.data);
        if (
          d.scormCommitData &&
          d.scormCommitData.courseId == state.courseId &&
          d.scormCommitData.userId == state.userInfo.userId &&
          JSON.stringify(d.scormCommitData) !=
            JSON.stringify(state.scormCommitData)
        ) {
          state.scormCommitData = d.scormCommitData;
          submitSection();
        }
      }
    };

    const playSection = (index) => {
      state.currentSpeed = "1.0";
      if (state.currentIndex == index) {
        proxy.$message.info($t("course.this_chapter_is_currently_playing"));
        // 当前正在播放此章节
        return false;
      }
      //按照顺序学
      if (
        index > 0 &&
        state.dataInfo.learnOrder == 1 &&
        state.dataInfo.details[index - 1].progress != 100
      ) {
        proxy.$message.info($t("XB_Completed_First"));
        // 必须先完成上一章节！
        return false;
      }
      //章节未转换完成提示
      if (state.dataInfo.details[index].status != 1) {
        proxy.$message.info($t("course.the_current_chapter_is_not_converted"));
        // 当前章节未转换完成
        return false;
      }
      //提交上一章节
      if (index != state.currentIndex && state.currentIndex != -1) {
        submitSection();
      }

      clearInterval(state.timer);
      state.timer = null;
      clearInterval(state.timer2);
      state.timer2 = null;
      state.pauseTime = 0;
      state.currentTime = 0;

      setTimeout(() => {
        state.currentIndex = index;
        state.currentSection = state.dataInfo.details[index];
        //计算文档类型的学习总时长
        if (state.currentSection.fileType == 3 && state.documentTimes > 0) {
          state.currentSection.times =
            state.documentTimes * state.currentSection.pages; // 文档学习总时长 = 每页多少秒 * 总页数
        }
        state.remainStudyTime = Math.ceil(
          state.currentSection.times -
            (state.currentSection.times * state.currentSection.progress) / 100
        ); // 剩余学习时长 = 总时长 - 已完成时长

        switch (state.currentSection.fileType) {
          case 0: //外部同步
            if (
              state.dataInfo.courseType == 5 &&
              state.dataInfo.providerType == 1
            ) {
              state.showItem = 11;
              zjyxSing({
                trainId: state.dataInfo.outSourceId,
                trainName: state.dataInfo.outSourceName,
                videoId: state.currentSection.outFileId,
              }).then((res) => {
                state.psign = res.data.psign;
                if (window.ccH5PlayerJsLoaded) {
                  ccPlay();
                }
              });
            }
            break;
          case 1: //视频
            if (isSafari || isWxWork) {
              state.filePath =
                state.currentSection.filePath.slice(
                  0,
                  state.currentSection.filePath.lastIndexOf(".")
                ) + "/1/mp4/1_1.mp4";
            } else {
              state.videoPlayer.format = "m3u8";
              state.filePath =
                state.currentSection.filePath.slice(
                  0,
                  state.currentSection.filePath.lastIndexOf(".")
                ) + "/1/hls/index.m3u8";
            }
            state.showItem = 1;
            showPlayer();
            break;
          case 2: //音频
            state.videoPlayer.format = "mp3";
            state.filePath = state.currentSection.filePath;
            if (state.currentSection.sourceId) {
              let path = state.currentSection.sourcePath;
              let suffix = path.substring(path.lastIndexOf(".") + 1);
              let pdfUrl = path.split(suffix)[0] + "pdf";
              state.sourcePath =
                "/pdfview/web/viewer.html?file=" +
                encodeURIComponent(
                  pdfUrl + "?" + state.currentSection.sourceName
                );
              delayHideLoading();
            } else {
              state.sourcePath = "";
            }
            state.showItem = 2;
            showPlayer();
            break;
          case 3: //文档
            state.showItem = 3;
            state.videoPlayer.format = "mp3";
            let pdfUrl =
              state.currentSection.filePath.split(
                state.currentSection.suffix
              )[0] + "pdf";
            state.filePath =
              "/pdfview/web/viewer.html?v=2&canfull=no&file=" +
              encodeURIComponent(pdfUrl + "?" + state.currentSection.fileName);
            playHandler();
            delayHideLoading();
            setTimeout(() => {
              console.log(isWxWork, isDingding);
              if (isWxWork || isDingding) {
              } else {
                window.addEventListener("message", receiveMessage, false);
              }
            }, 500);
            break;
          case 4: //图片
            state.showItem = 4;
            state.filePath = state.currentSection.filePath;
            playHandler();
            delayHideLoading();
            break;
          case 5: //文本
            state.showItem = 5;
            state.filePath =
              "2月19日，元宵节。今年，“十五的月亮十五圆”。根据精确推算，" +
              "19日17时3分月球过近地点的距离只有35.68万千米，是月球全年距离地球最近的一刻，23时54分，月亮呈现当夜“最圆”。只要天气晴好，" +
              "我国公众可欣赏到一轮皎洁明亮的“超级元宵月”！会计师对话框方式";
            playHandler();
            delayHideLoading();
            break;
          case 6: //H5
            state.showItem = 3;
            showUrl();
            break;
          case 9: //文本
            state.isAllowcopying = state.currentSection.isAllowcopying;
            state.showItem = 9;
            state.filePath =state.currentSection.content;
            playHandler();
            delayHideLoading();
            break;
          case 10: //Scorm
            state.showItem = 10;
            let scormUrl = state.currentSection.home;
            state.filePath =
              // //data-cdn.51-learning.cn/study
              "/scorm/viewer.html?courseId=" +
              state.courseId +
              "&userId=" +
              state.userInfo.userId +
              "&scormUrl=" +
              encodeURIComponent(scormUrl) +
              "&scorm=" +
              JSON.stringify(state.currentSection.scorm);
            playHandler();
            delayHideLoading();
            window.addEventListener("message", receiveMessage, false);
            break;
          case 8: //Url
            state.showItem = 3;
            showUrl();
            break;
          default:
            break;
        }
      }, 100);
    };

    const showPlayer = () => {
      setTimeout(() => {
        //禁用倍数播放
        if (
          state.dataInfo.multiplePlay == 2 &&
          state.currentSection.progress != 100
        ) {
          document.getElementsByClassName(
            "prism-setting-btn"
          )[0].style.display = "none";
        }
      }, 100);
      var el =
        document.getElementsByTagName("video")[0] ||
        document.getElementsByTagName("audio")[0];
      if (el && aliplayer.value) {
        //重新创建播放器
        aliplayer.value.scriptTagStatus = 2;
        aliplayer.value.initAliplayer();
      }
    };
    const showUrl = () => {
      state.filePath = state.currentSection.filePath.replace(
        ".zip",
        "/" + state.currentSection.home
      );
      playHandler();
      delayHideLoading();
    };

    //开始播放时触发
    // const playHandler = _.throttle(playHandlerThrottle, 100);
    const playHandler = _.throttle(() => {
      state.startIndex += 1;
      state.isPause = false;
      submitSection(false);
      if (ccplayer.value) {
        //从上次播放的位置开始播放音频
        if (state.setTimeFlag == 1) {
          let getDuration = ccplayer.value.getDuration() || 0; //获取视频总时长
          let cktime = Math.floor(state.currentSection.location); //获取视频最后播放位置
          // 跳转到某个时刻进行播放
          if (cktime > 0 && cktime != Math.floor(getDuration)) {
            ccplayer.value.jumpToTime(cktime);
          }
          state.setTimeFlag = 2;
        }
        state.dataInfo.location = ccplayer.value.getPosition();
      }
      if (state.startIndex == 1) {
        // 当第一次点击播放按钮
        // state.loadingPlayer = true;
        startInterval();
        speedInterval();
        bindAntiCheating();
        state.setTimeFlag = 1;
        //添加学习数据
        state.logId = 0;
        if (state.learnId == 0) {
          startLearn();
        } else {
          submitSection();
        }
      } else if (state.startIndex > 1 && state.pauseTime == 1) {
        // 暂停后点击播放按钮时触发
        state.pauseTime = 0;
        startInterval();
        speedInterval();
      } else {
        // 当切换章节时触发
        // state.loadingPlayer = true;
        startInterval();
        speedInterval();
        state.setTimeFlag = 1;
        state.logId = 0;
        submitSection();
      }
    }, 100);
    // 暂停播放时触发
    const pauseHandler = () => {
      // 音视频切换到其它章节  倒计时 学习时长不计时
      [1, 2].includes(state.showItem) && (state.isPause = true);
      submitSection(false);
      if (state.startIndex > 0) {
        state.pauseTime = 1;
      }
    };

    const speedChangeHandler = (val) => {
      if (val) {
        submitSection();
        state.currentSpeed = val;
        speedInterval();
      }
    };

    // 播放位置发生改变时触发
    const onPlayerTimeupdate = () => {
      if (aliplayer.value == null) {
        return false;
      }
      //从上次播放的位置开始播放音频
      if (state.setTimeFlag == 1) {
        let getDuration = aliplayer.value.getDuration() || 0; //获取视频总时长
        let cktime = Math.floor(state.currentSection.location); //获取视频最后播放位置
        // 跳转到某个时刻进行播放
        if (cktime > 0 && cktime != Math.floor(getDuration)) {
          aliplayer.value.seek(cktime);
        }
        // 延时 隐藏 loading 效果
        delayHideLoading();
        state.setTimeFlag = 2;
      }
      if (state.currentSection.progress == 100) {
        //启用倍数播放
        document.getElementsByClassName("prism-setting-btn")[0].style.display =
          "initial";
      }
      if (
        state.dataInfo.draggable == 2 &&
        state.currentSection.progress != 100
      ) {
        //最大播放时间
        let currentPlayTime = state.currentSection.location;
        // 时间差小于1时 正常记录播放时间
        let time = aliplayer.value.getCurrentTime() - currentPlayTime;
        if (time <= 1 && aliplayer.value.getCurrentTime() >= currentPlayTime) {
          //记录最大播放时间
          state.currentSection.location = aliplayer.value.getCurrentTime();
        } else {
          //如果当前时间大于最大播放时间就跳回最大播放时间
          if (aliplayer.value.getCurrentTime() > currentPlayTime) {
            aliplayer.value.seek(currentPlayTime);
          }
          // 时长大于视频总时长导致视频不能再播放
          if (currentPlayTime > state.currentSection.times) {
            state.currentSection.location = 0;
          }
        }
      } else {
        state.currentSection.location = aliplayer.value.getCurrentTime();
      }
    };
    // 当结束播放时触发
    const onPlayerEnded = () => {
      // 当前播放结束章节不是最后章节，就继续播放下一章节
      if (state.dataInfo.details.length > parseInt(state.currentIndex + 1)) {
        // 延时加载 在播放结束后会自动暂停，导致pauseTime数据错误。
        setTimeout(() => {
          playSection(state.currentIndex + 1);
        }, 10);
      } else {
        submitSection();
      }
    };
    const startInterval = () => {
      clearInterval(state.timer);
      state.timer = null;
      state.timer = setInterval(() => {
        if (state.isPause) {
          return false;
        }
        if (state.currentSection.progress == 100) {
          return false;
        }
        // state.remainStudyTime -= 1 * state.currentSpeed;
        if (state.showItem == 1 || state.showItem == 2) {
          if (state.remainStudyTime <= 10) {
            state.intervalSpeed = 5;
          } else if (state.remainStudyTime <= 30) {
            state.intervalSpeed = 10;
          } else {
            state.intervalSpeed = 30;
          }
        } else {
          state.intervalSpeed = 30;
        }
        state.currentTime += 1;
        //每30秒提交一次 || 剩余学习时间 == 0
        if (
          state.currentTime % state.intervalSpeed == 0 ||
          state.remainStudyTime == 0
        ) {
          if (state.currentSection.fileType != 10) submitSection();
        }
      }, 1000);
    };
    const speedInterval = () => {
      clearInterval(state.timer2);
      state.timer2 = null;
      state.timer2 = setInterval(() => {
        if (state.isPause) {
          return false;
        }
        state.remainStudyTime--;
      }, 1000 / state.currentSpeed);
    };
    // 开始学习
    const startLearn = () => {
      let params = {
        taskId: state.taskId,
        stageId: state.sid,
        detailId: state.did,
        courseId: state.courseId,
        sectionId: state.currentSection.documentId,
        location: parseInt(state.currentSection.location),
        logId: state.logId,
        learnId: 0,
        face: state.facePic,
      };
      if (state.currentSection.fileType == 10) {
        params.scorm = {
          progress: 0,
          score: 0,
          location: 0,
          exit: "",
          mode: "",
          suspend: "",
          sessionTime: "0000:00:00.00",
          time: 0,
          status: "not attempted",
        };
      }
      let action = courseStartLearn;
      if (state.taskId > 0) {
        action = taskStartLearn;
      }
      action(params).then((data) => {
        if (data.ret == 0) {
          state.learnId = data.data.learnId;

          // 任务下的课程是同步完成，课程完成条件相关数据就走课程的 即把taskId赋值为0
          if (
            state.taskId > 0 &&
            data.data.completeFromSync &&
            state.plusTaskId !== 0
          ) {
            state.plusTaskId = 0;
            getPlusDetail();
          }
          updateData(data.data);
        }
      });
    };
    // 提交章节学习进度
    const submitSection = (isShowErr = true) => {
      let index = state.currentIndex;
      let params = {
        taskId: state.taskId,
        stageId: state.sid,
        detailId: state.did,
        courseId: state.courseId,
        sectionId: state.currentSection.documentId,
        location: parseInt(state.currentSection.location),
        logId: state.logId,
        learnId: state.learnId,
        multipleOfVideo: parseFloat(state.currentSpeed),
        isPause: state.isPause,
      };
      if (state.currentSection.fileType == 10) {
        params.scorm = {};
        params.scorm.progress = state.scormCommitData.progress || 0;
        params.scorm.score = state.scormCommitData.score || 0;
        params.scorm.location = state.scormCommitData.location || 0;
        params.scorm.exit = state.scormCommitData.exit || "";
        params.scorm.mode = state.scormCommitData.mode || "";
        params.scorm.suspend = state.scormCommitData.suspend || "";
        params.scorm.sessionTime = state.scormCommitData.sessionTime || "";
        params.scorm.time = state.scormCommitData.time || 0;
        params.scorm.status = state.scormCommitData.status || "not attempted";
        if (params.scorm.status == "passed") params.scorm.status == "completed";
      }
      let action = courseLearning;
      if (state.taskId > 0) {
        action = taskLearning;
      }
      action(params)
        .then((data) => {
          if (data.ret == 0) {
            updateData(data.data, index);
            if (window.opener) {
              let parent = window.opener;
              parent.postMessage("reload-project", location.origin);
            }
          }
        })
        .catch((err) => {
          //失败回调
          let isOffLine = Boolean(err && !navigator.onLine && isShowErr);
          if (isOffLine) {
            stopCallBack(false);
            alert($t("networkErrMsg"));
          }
        });
    };
    /**
     *
     * 计算课程总进度
     * 完成进度 = 章节总进度 + (完成条件数 * 100)
     * 总进度 = (课程总章节数 + 条件总数) * 100
     * (完成进度 / 总进度) * 100
     * 课程完成不在计算进度
     */
    const updataProgress = () => {
      let sectionProgress = 0;
      let section = state.dataInfo.details.length;
      state.dataInfo.details.forEach((element, i) => {
        sectionProgress += element.progress;
      });
      state.isCanExam = sectionProgress === section * 100;

      if (state.dataInfo.progress === 100) return;

      let completeProgress = sectionProgress + state.completeTotals * 100;

      let totalProgress = (section + state.taskTotals) * 100;

      state.dataInfo.progress = Math.ceil(
        (completeProgress / totalProgress) * 100
      );
    };
    const updateData = (data, index) => {
      state.logId = data.logId;
      let Section =
        index != null ? state.dataInfo.details[index] : state.currentSection;
      if (data.complete && Section.documentId === data.sectionId) {
        Section.progress = 100;
      } else if (Section.documentId === data.sectionId) {
        Section.progress = data.progress;
      }
      updataProgress();
      //开启同步学习进度时 同步学习位置
      if (state.dataInfo.syncData == 1 && state.setTimeFlag == 1) {
        Section.location = data.location;
      }
    };
    //延时隐藏加载效果，默认延迟0.1秒
    const delayHideLoading = (time = 100) => {
      setTimeout(() => {
        state.loadingPlayer = false;
      }, time);
    };

    //课程防作弊
    const bindAntiCheating = () => {
      //开启防作弊 && 总学习时长大于15分钟
      if (state.dataInfo.preventOnhook > 0) {
        state.antiCheatingTimer = setInterval(() => {
          clearInterval(state.antiCheatingTimer);
          state.antiCheatingTimer = null;
          stopCallBack();
          Modal.warning({
            title: () => $t("XB_WarmTip"),
            // 温馨提示
            content: () => $t("course.confirm_learning"),
            // 请确认您是否还在学习？
            okText: () => $t("CM_Confirm"),
            // 确定
            keyboard: false,
            onOk: () => {
              yesCallBack();
              bindAntiCheating();
            },
          });
        }, state.remindTime * 1000); // 每{0}分钟提示一次
      }
    };

    const yesCallBack = () => {
      //开始倒计时，开始播放
      state.isPause = false;
      if (aliplayer.value) {
        aliplayer.value.play();
      }
      if (ccplayer.value) {
        ccplayer.value.play();
      }
    };

    const stopCallBack = (isSubmit = true) => {
      //停止倒计时，暂停播放
      state.isPause = true;
      if (aliplayer.value) {
        //视频
        aliplayer.value.pause();
        //退出全屏
        if (aliplayer.value.getIsFullScreen()) {
          aliplayer.value.cancelFullScreen();
        }
        // 视频暂停 现在暂停按钮
        const stopBtn = document.querySelector(".prism-big-play-btn");
        stopBtn && (stopBtn.style.display = "block");
        //为确保学习进度不丢失，每次弹出防作弊时提交一次学习记录
        isSubmit && submitSection();
      }
      if (ccplayer.value) {
        //视频
        ccplayer.value.pause();
        //退出全屏
        if (ccplayer.value.isFullScreen()) {
          ccplayer.value.normalScreen();
        }
        //为确保学习进度不丢失，每次弹出防作弊时提交一次学习记录
        isSubmit && submitSection();
      }
    };

    const getCourseStudyList = () => {
      members(state.searchParams).then((res) => {
        if (res.ret == 0) {
          //当学习人数为0时，显示当前用户在学
          if (res.data.list == null) {
            res.data.list = [
              {
                userId: state.userInfo.userId,
                realName: state.userInfo.realName,
                progress: 0,
                portrait: state.userInfo.portrait,
                lastTime: 0,
                department: state.userInfo.departmentName,
              },
            ];
          }
          state.courseStudyList.push.apply(
            state.courseStudyList,
            res.data.list
          );

          state.courseStudyTotal = res.data.totals;
          //每次拿到数据后页数加1
          state.searchParams.page += 1;
          // 加载状态结束
          state.loading = false;
          // 数据全部加载完成
          if (state.courseStudyList.length >= res.data.totals) {
            state.finished = true;
          }
        }
      });
    };
    const loadMore = () => {
      state.loading = true;
      // 异步更新数据
      setTimeout(() => {
        getCourseStudyList();
      }, 100);
    };

    //获取推荐课程列表
    const getRecommendList = () => {
      let params = {
        page: 1,
        pageSize: 50,
        name: "",
        order: 2,
        status: 0,
        folderId: 0,
        types: [],
      };
      courseList(params).then((res) => {
        if (res.ret == 0 && res.data.list != null) {
          state.recommendList = shuffle(res.data.list);
        }
      });
    };
    //刷一刷
    const brushList = () => {
      state.recommendList = shuffle(state.recommendList);
    };
    //收藏
    const clickFavorite = () => {
      favorite({ id: state.courseId }).then((data) => {
        if (data.ret == 0) {
          state.dataInfo.isFavorite = data.data;
          if (state.dataInfo.isFavorite) {
            proxy.$message.success($t("CM_CollectionSuccess"));
            // 收藏成功
            state.dataInfo.favorites = state.dataInfo.favorites + 1;
          } else {
            proxy.$message.success($t("CM_CancelFavoriteSuccess"));
            // 取消收藏成功
            state.dataInfo.favorites = state.dataInfo.favorites - 1;
          }
        }
      });
    };
    //评分
    const rateScore = (score) => {
      star({ id: state.courseId, star: score }).then((data) => {
        if (data.ret == 0) {
          proxy.$message.success($t("course.thank_you_for_your_rating"));
          // 感谢您的评分！
          state.dataInfo.starUsers += 1;
          state.dataInfo.myStar = score;
          state.dataInfo.starScore = state.dataInfo.starScore + score; // 总分数
          starComplete();
        }
      });
    };
    const backTop = () => {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
    };
    //生成随机数组
    const shuffle = (array) => {
      var m = array.length,
        t,
        i;
      while (m) {
        i = Math.floor(Math.random() * m--);
        t = array[m];
        array[m] = array[i];
        array[i] = t;
      }
      return array;
    };

    const fullScreenHandler = () => {
      let water = document.getElementsByClassName("water-mark")[0];
      let wrap = document.getElementsByClassName("prism-player")[0];
      let water2 = water.cloneNode(true);
      wrap.appendChild(water2);
    };

    const cancelFullScreenHandler = () => {};

    const formatSeconds = (value) => {
      let hours = Math.floor(value / (60 * 60));
      let modulo = value % (60 * 60);
      let minutes = Math.floor(modulo / 60);
      let seconds = modulo % 60;
      if (hours > 0) {
        if (hours < 10) {
          hours = "0" + hours;
        }
      }
      if (minutes < 10) {
        minutes = "0" + minutes;
      }
      if (seconds < 10) {
        seconds = "0" + seconds;
      }
      if (hours > 0) {
        return hours + ":" + minutes + ":" + seconds;
      } else {
        return minutes + ":" + seconds;
      }
    };

    // 切屏
    const visProp = onHide();
    const isStopModal = ref(false);
    const screenVisibility = () => {
      if (visProp) {
        if (isStopModal.value) return false;
        let e = document[onShow()];
        if (e === "hidden") {
          stopCallBack(false);
        } else if (e === "visible") {
          // 不是音视频 弹窗确认
          if (![1, 2].includes(state.currentSection.fileType)) {
            isStopModal.value = true;
            Modal.destroyAll();
            Modal.confirm({
              title: () => $t("course.study_stop_continue"),
              // 当前学习已暂停，是否继续学习
              icon: () => createVNode(ExclamationCircleOutlined),
              keyboard: false,
              centered: true,
              cancelText: () => createVNode(""),
              onOk() {
                isStopModal.value = false;
                yesCallBack();
              },
            });
          }
        }
      }
    };

    /**
     *
     * 三院 火狐浏览器 跳转到谷歌浏览器
     * 1.windows XP 2.火狐浏览器 版本 小于等于 52
     *
     */
    const htsyVisible = ref(false);
    const openHref = ref(false);
    const dataPath = location.protocol + "//" + location.host;
    const getIsXPFF = () => {
      const userAgent = navigator.userAgent.toLowerCase();
      const xp =
        userAgent.indexOf("windows nt 5.1") > -1 ||
        userAgent.indexOf("windows nt 5.2") > -1;
      const ff = userAgent.indexOf("firefox") > -1;
      const ffv = userAgent.split(" ").reverse()[0].split("/")[1];
      console.log(userAgent, ffv, "-----userAgent-----");
      return xp && ff && ffv <= 52;
    };
    const handleHTSY = () => {
      createAuth().then((res) => {
        if (res.ret === 0) {
          openHref.value = `OpenChrome:${location.protocol}//${location.host}/course/detail?htsyautoauth=${res.data.key}*${state.courseId}`;
          console.log(openHref, "--openHref--");
          htsyVisible.value = true;
          // Modal.confirm({
          //   title: () => "Firfox版本较低，请使用Google浏览器播放课程",
          //   icon: () => createVNode(ExclamationCircleOutlined),
          //   keyboard: false,
          //   centered: true,
          //   cancelText: () => createVNode(""),
          //   okText: () => h("span", "使用Google播放"),
          //   onOk() {
          //     window.open(openHref);
          //     router.replace("/course");
          //   },
          // });
        }
      });
    };
    const handleOk = () => {
      window.open(openHref.value);
      htsyVisible.value = false;
      router.replace("/course");
    };

    const homeworkRef = ref(null);
    const rateRef = ref(null);
    const handelCondition = (val) => {
      let params = {
        courseId: state.courseId,
      };
      state.plusTaskId && (params.taskId = state.plusTaskId);
      switch (val) {
        case 1:
          if (state.questionnaire.questionnaireId) {
            router.push({
              path: "/questionnaire/detail",
              query: {
                ...params,
                coursePlusId: state.questionnaire.coursePlusId,
                resourceId: state.questionnaire.questionnaireId,
                evalType: 3,
                source: "course",
                progress: state.questionnaire.customStatus == 2 ? 100 : 0,
              },
            });
          } else {
            rateRef.value.show();
          }
          break;
        case 2:
          homeworkRef.value.openModal({
            ...params,
            coursePlusId: state.homework.coursePlusId,
          });
          break;
        case 3:
          if (!state.isCanExam) {
            // 课程章节未学完，暂不可考试
            return proxy.$message.error($t("course.need_tip"));
          }
          router.push({
            path: "/exam/detail",
            query: {
              ...params,
              coursePlusId: state.exam.coursePlusId,
            },
          });
          break;
      }
    };
    const previewDoc = (data) => {
      // if (data.suffix === "zip") {
      //   return proxy.$message.error($t("bbs.zip_not_preview"));
      // }
      getFileDetail({ id: data.documentId }).then((res) => {
        if (res.ret == 0) {
          const fileInfo = res.data;
          state.previewFile.visible = true;
          state.previewFile.fileStatus = fileInfo.status;
          state.previewFile.documentId = fileInfo.documentId || 0;
          state.previewFile.fileType = fileInfo.fileType;
          state.previewFile.filePath = fileInfo.fileType == 9 ? fileInfo.content : fileInfo.filePath;
          state.previewFile.content = fileInfo.content;
          state.previewFile.fileName = fileInfo.fileName || "";
          state.previewFile.fileHome = fileInfo.home || "";
          state.previewFile.isAllowcopying = fileInfo.isAllowcopying;
        } else {
          proxy.$message.error(res.msg);
        }
      });
    };

    const changeTabs = (val) => {
      if (val == 2 && state.memberOnce) {
        getCourseStudyList();
        state.memberOnce = false;
      }
    };

    onMounted(() => {
      if (getIsXPFF()) {
        handleHTSY();
      } else {
        getDetail();
        getRecommendList();
        window.on_CCH5player_pause = pauseHandler;
        window.on_CCH5player_play = playHandler;
        window.on_CCH5player_ended = onPlayerEnded;
        if (visProp) {
          let evtname = visProp.replace(/[H|h]idden/, "") + "visibilitychange";
          document.addEventListener(evtname, screenVisibility);
        }
      }
    });

    const networkError = () => {
      if (!state.isPause) {
        stopCallBack(false);
        alert($t("networkErrMsg"));
      }
    };

    window.addEventListener("offline", networkError);

    onUnmounted(() => {
      if (state.timer) {
        // 清除定时器
        clearInterval(state.timer);
        state.timer = null;
        clearInterval(state.timer2);
        state.timer2 = null;
        submitSection();
      }
      // 清除防作弊定时器
      if (state.antiCheatingTimer) {
        clearInterval(state.antiCheatingTimer);
        state.antiCheatingTimer = null;
      }
      if (ccplayer.value) {
        ccplayer.value.destroy();
        ccplayer.value = null;
      }
      if (visProp) {
        let evtname = visProp.replace(/[H|h]idden/, "") + "visibilitychange";
        document.removeEventListener(evtname, screenVisibility);
      }
      window.removeEventListener("offline", networkError);
    });

    //点击下载
    const clickDownload = (item) => {
      state.downloading = true;
      if (state.openDownloadWatermark) {
        //下载后文件名
        if (item.fileType == 1 || item.fileType == 3) {
          toDownload({
            fileName: item.fileName,
            filePath: item.sourceFilePath,
            isGenerateWatermark: true,
            source:
              "【" +
              $t("course.online_course") +
              "】" +
              state.dataInfo.courseName,
            title: item.fileName2,
          }).then((res) => {
            state.downloading = false;
            proxy.$message.info($t("downloadCenterTips"));
            // Modal.confirm({
            //   icon: createVNode(ExclamationCircleOutlined),
            //   content: $t("downloadCenterTips"),
            //   okText: $t("CM_Affirm"),
            //   cancelText: $t("CM_Cancel"),
            //   onOk() {
            //     router.push("/mine/down");
            //   },
            // });
          });
        } else {
          fileDownload(item.sourceFilePath, item.fileName);
        }
      } else {
        fileDownload(item.sourceFilePath, item.fileName);
      }
    };

    return {
      newWindow: store.getters.companyInfo.useNewWindow,
      ...toRefs(state),
      playSection,
      getFileType,
      playHandler,
      pauseHandler,
      speedChangeHandler,
      fullScreenHandler,
      cancelFullScreenHandler,
      onPlayerEnded,
      onPlayerTimeupdate,
      aliplayer,
      formatSeconds,
      brushList,
      loadMore,
      backTop,
      clickFavorite,
      rateScore,
      convertTimes,
      formatTime,
      startPlay,
      isSafari,
      isWxWork,
      htsyVisible,
      handleOk,
      dataPath,
      homeworkRef,
      getPlusDetail,
      rateRef,
      handelCondition,
      dateFormat,
      previewDoc,
      fileDownload,
      changeTabs,
      clickDownload,
    };
  },
};

</script>

<style lang="less" scoped>
.mod-1 {
  position: relative;
  background: #191b1f;
  width: 100%;
  height: 715px;
  color: #ccc;
  .content {
    .mixinWrap();
    height: 602px;
    .top {
      padding: 10px 0px;
      height: 40px;
      text-align: right;
      .green {
        color: #1ab700;
      }
    }
    ::v-deep(.ant-spin-nested-loading > div > .ant-spin) {
      max-height: initial;
      width: 75%;
    }
    .playArea {
      height: 562px;
    }
    .left {
      position: relative;
      .fl();
      width: 890px;
      height: 100%;
      background: #090909;
      text-align: left;
      padding: 10px;
      #CCPlay,
      .video-player {
        width: 100%;
        height: 100%;
      }
      .audio-pdf {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 40px;
        top: 0;
      }
    }
    .left.background .video-player {
      ::v-deep(.prism-player) {
        background: url("../../assets/image/audio-cover.gif") no-repeat;
        background-size: 100% 100%;
      }
    }
    .right {
      .fl();
      width: 310px;
      height: 100%;
      background: #2d2d2d;
      border-right: 1px solid #2d2d2d;
      border-top: 1px solid #2d2d2d;
      border-bottom: 1px solid #2d2d2d;
      .scrollbar();
      ul {
        height: 100%;
        overflow-y: auto;
      }
      li {
        position: relative;
        height: 70px;
        width: 100%;
        border-bottom: 1px solid #333333;
        cursor: pointer;
        .catalog-detail {
          padding: 11px 22px 11px 48px;
          .title {
            color: #999999;
            font-size: 16px;
            margin: 0 0 8px 0;
            font-weight: normal;
            .mixinEllipsis(22px);
          }
          .info {
            color: #666666;
            font-size: 12px;
            span:not(:last-child) {
              margin-left: 2px;
              margin-right: 50px;
            }
          }
        }
      }
      li.on {
        background: #1e1e1e;
      }
    }
  }
}
.mod-2 {
  position: relative;
  background: @color-page-light;
  .float {
    width: 66px;
    height: 66px;
    display: block;
    font-size: 26px;
    font-weight: 400;
    color: #282828;
    background: #fff;
    text-align: center;
    .anticon {
      padding: 21px 8px;
      border-bottom: 1px solid #f5f5f5;
    }
  }
  .float:nth-last-child(1) .anticon {
    border-bottom: none;
  }
  .course-detail {
    background: #fff;
    border-radius: 4px;
    padding: 30px 150px 30px 30px;
    width: 1200px;
    margin: 0 auto;
    position: relative;
    top: -63px;
    .title {
      height: 30px;
      line-height: 30px;
      margin-bottom: 20px;
      .ti {
        .mixinEllipsis(30px);
        font-size: 30px;
        color: #202020;
        max-width: 70%;
        display: -webkit-inline-box;
        float: left;
      }
      .num {
        font-size: 18px;
        color: #999999;
        float: left;
      }
      .progress {
        font-size: 18px;
        font-weight: bold;
        color: #202020;
        float: right;
        em {
          color: #286eff;
        }
      }
    }
    .rate {
      display: flex;
      margin-bottom: 26px;
      .ss {
        font-size: 30px;
        color: #f5961b;
        font-weight: 700;
        margin-right: 15px;
        line-height: 1.15;
      }
      .num {
        margin-top: 11px;
        color: #999;
        font-size: 14px;
      }
    }
    .details {
      .mixinFlex();
      .tag {
        position: relative;
        padding-right: 15px;
        margin-right: 15px;
        font-size: 16px;
        font-weight: 550;
        color: #333;
        &::after {
          content: "";
          position: absolute;
          top: 7px;
          right: 0;
          height: 12px;
          width: 1px;
          background-color: #d9d9d9;
        }
      }
      .other {
        .mixinFlex();
        flex-wrap: wrap;
        font-size: 16px;
        &-item {
          .mixinFlex();
          margin-right: 30px;
          margin-bottom: 5px;
          .label {
            padding-right: 10px;
            color: #999;
          }
          .value {
            color: #333;
          }
        }
        .intro {
          min-width: 100%;
        }
      }
    }
    .total-progress {
      position: absolute;
      top: 30px;
      right: 30px;
      .percent {
        font-size: 30px;
        font-weight: 550;
        color: #108ee9;
      }
      .progress-txt {
        margin-top: 10px;
        font-size: 16px;
        color: #999;
      }
    }
  }
  .course-setting {
    padding: 30px;
    width: 1200px;
    min-height: 100px;
    margin: -33px auto 30px;
    background: #fff;
    border-radius: 8px;
    .title {
      margin-bottom: 30px;
      font-size: 20px;
      font-weight: 550;
      color: #333;
    }
    .setting {
      .mixinFlex();
      &-item {
        // flex: 1;
        width: 366px;
        position: relative;
        .mixinFlex(flex-start, center);
        min-height: 88px;
        margin-right: 20px;
        padding: 20px 94px 20px 12px;
        background-color: #f8f8f8;
        border-radius: 8px;
        &:last-child {
          margin-right: 0;
        }
        .icon {
          width: 48px;
          height: 48px;
          margin-right: 10px;
        }
        .quesition-icon {
          background: url("~@/assets/image/course/quesition-icon.png") no-repeat;
          background-size: 100% 100%;
        }
        .homework-icon {
          background: url("~@/assets/image/course/homework-icon.png") no-repeat;
          background-size: 100% 100%;
        }
        .exam-icon {
          background: url("~@/assets/image/course/exam-icon.png") no-repeat;
          background-size: 100% 100%;
        }
        .info {
          flex: 1;
          font-size: 14px;
          .type {
            margin-bottom: 6px;
            color: #333;
          }
          .uncomplete {
            font-size: 12px;
            color: #ff3131;
          }
          .complete {
            .mixinFlex(flex-start, center);
            .score {
              margin-right: 12px;
              font-weight: bold;
              color: #44b44f;
            }
            .nopass {
              color: #ff3131;
            }
            .status {
              margin-right: 12px;
              font-weight: 550;
            }
            .status-0 {
              color: #ff3131;
            }
            .status-1 {
              color: #e8993e;
            }
            .status-2 {
              color: #44b44f;
            }
            .time {
              font-size: 12px;
              color: #999;
            }
          }
        }
        .btn {
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          right: 12px;
          width: 72px;
          z-index: 2;
        }
        .complete-icon {
          position: absolute;
          top: 0;
          right: 0;
          width: 50px;
          height: 50px;
          background: url("~@/assets/image/course/complete-icon.png") no-repeat;
          background-size: 100% 100%;
        }
      }
    }
  }
  .course-other {
    display: flow-root;
    position: relative;
    // top: -40px;
    width: 1200px;
    margin: 0 auto;
    min-height: 300px;
    .left {
      .fl();
      width: 890px;
      min-height: 627px;
      padding: 10px 30px 30px;
      background: #fff;
      border-radius: 4px;
      margin-bottom: 30px;
      .catalog-ul {
        li {
          position: relative;
          width: 100%;
          cursor: pointer;
          margin-bottom: 14px;
          color: #202020;
          font-size: 16px;
          .index {
            position: absolute;
            top: 20px;
            left: 15px;
          }
          .catalog-detail {
            padding: 20px 105px 20px 90px;
            background: #f5f5f6;
            .title {
              margin: 0 0 8px 0;
              .mixinEllipsis(22px);
            }
            .info {
              color: #999999;
              font-size: 14px;
              span:not(:last-child) {
                margin-left: 2px;
                margin-right: 20px;
              }
            }
          }
          .zt {
            font-size: 16px;
            color: #d8d8d8;
            position: absolute;
            top: 20px;
            right: 24px;
            color: #d8d8d8;
            text-align: right;
            .icon-wc {
              background-image: url(../../assets/image/kczj-dui.png);
              background-size: 100% 100%;
              width: 17px;
              height: 15px;
              display: inline-block;
              margin-right: 6px;
              vertical-align: -2px;
            }
          }
        }
        li:nth-last-child(1) {
          margin-bottom: 0;
        }
        li.complete .zt {
          color: #1ab700;
        }
        li.ing .zt {
          color: #3996fe;
        }
      }
      .rate {
        font-size: 16px;
        font-weight: 600;
        color: #333333;
        margin-bottom: 15px;
      }
    }
    .right {
      .fr();
      width: 290px;
      background: #fff;
      border-radius: 4px;
      .ant-card-bordered {
        border-radius: 4px;
      }
      .ant-card-extra a {
        color: #999999;
      }
      .tjkc-li {
        display: block;
        position: relative;
        margin-bottom: 20px;
        .coverImg {
          position: absolute;
          width: 120px;
          height: 68px;
          border-radius: 4px;
        }
        .info {
          padding-left: 128px;
          p {
            font-size: 12px;
            color: #999999;
            margin: 0;
          }
          p.title {
            color: #333333;
            .mixinEllipsis(12px);
            margin-bottom: 20px;
          }
        }
      }
      .tjkc-li:nth-last-child(1) {
        margin-bottom: 0;
      }
    }
  }
  .top40 {
    top: -40px;
  }
}
.modal-title {
  .mixinFlex(flex-start; center);
  margin-bottom: 20px;
  font-size: 16px;
  // vertical-align: baseline;
  span {
    padding-right: 10px;
    font-size: 22px;
    color: #faad14;
  }
}
.modal-tips {
  a {
    color: #2d60d5;
  }
}
.water-mark {
  pointer-events: none;
  z-index: 999;
  position: absolute;
  right: 20px;
  top: 20px;
  font-size: 24px;
  color: rgba(170, 170, 170, 0.4);
  line-height: 40px;
}
.water-marks {
  pointer-events: none;
  z-index: 999;
  position: absolute;
  right: 0;
  left: 0;
  top: 42px;
  bottom: 0;
  overflow: hidden;
  .item {
    float: left;
    transform: rotate(-20deg);
    margin: 50px 50px 250px 50px;
    font-size: 18px;
    line-height: 40px;
    color: rgba(170, 170, 170, 0.2);
    pointer-events: none;
  }
}
.doc {
  &-item {
    .mixinFlex(flex-start; center);
    padding: 10px;
    border-bottom: 1px solid #f5f5f5;
    font-size: 14px;
    .title {
      flex: 1;
      .mixinEllipsis(22px, 1);
      cursor: pointer;
      &:hover {
        color: var(--theme);
      }
    }
    .action {
      widows: 50px;
    }
  }
}
.unselectable {
  user-select: none; /* 阻止文本选择 */
  -webkit-user-select: none; /* 针对Webkit内核的浏览器 */
  -moz-user-select: none; /* 针对Firefox浏览器 */
  -ms-user-select: none; /* 针对IE和Edge浏览器 */
}
</style>
